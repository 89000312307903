import React, { useState } from 'react';

function Folder({handleFileChange}) {
    return (
        <div className='main-container-folder'>
            <h4>Start adding Your File</h4>
            <h5 className='drag-style'>Drag and Drop files or upload from your computer</h5>
            <label htmlFor="imageInput1">
                <p className="upload-media-1 btn">
                    + Upload Media
                </p>
                <input
                    type="file"
                    id="imageInput1"
                    name="images"
                    accept="image/*"
                    className="form-control"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                />
            </label>
        </div>
    );
}

export default Folder;

