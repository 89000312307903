import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';

const DeletePopupModal = ({deletePopupType,setDeletepopupType}) => {
    const [showSuccess, setSuccessShow] = useState(false);
    const handleSuccessClose = () =>{
        setSuccessShow(false);
        setDeletepopupType("")
     } 
    const handleSuccessShow = () => setSuccessShow(true);
    const [showError, setErrorShow] = useState(false);
    const handleErrorClose = () => setErrorShow(false);
    const handleErrorShow = () => setErrorShow(true);
    const GetData = () => {
        if(deletePopupType=="success"){
            setSuccessShow(true);
        }else{
            setErrorShow(true);
        }
    }
    useEffect(() => {
        GetData();
    }, [deletePopupType])
    return (
        <div>
            <Modal show={showSuccess} onHide={handleSuccessClose}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                        <Modal.Body>
                        <div className="success-icon"> 
                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> 
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                            </svg>
                        </div>
                            <h5 className="successmsg">Record Deleted Successfully !</h5>
                        </Modal.Body>
                    <Modal.Footer>
                    <button variant="primary" className="btn btn-primary"  onClick={handleSuccessClose}>
                        Ok
                    </button>
                    </Modal.Footer>
                </Modal>
                
            <Modal show={showError} onHide={handleErrorClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                    <Modal.Body>
                    
                    <div className="error-icon">
                        <svg className="crossmark animateElement" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle className="crossmark__circle animateElement" cx="26" cy="26" r="25" fill="none"/>
                            <path className="cross__path cross__path--right animateElement" fill="none" d="M16,16 l20,20" />
                            <path className="cross__path cross__path--left animateElement" fill="none" d="M16,36 l20,-20" />
                        </svg>
                    </div>
                        <h5 className="errormsg">Something went wrong, Please try again !</h5>
                    </Modal.Body>
                <Modal.Footer>
                <button variant="primary" className="btn btn-primary"  onClick={handleErrorClose}>
                    Ok
                </button>
                </Modal.Footer>
            </Modal>
            
        </div>
    );
}

export {DeletePopupModal};
