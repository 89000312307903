import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/js/bootstrap.min.js";
import {
  StaffLogin,
  Dashboard,
  DashboardLayout,
  Layout,
  Homedesign,
  TextBlock,
  FullWidthBanners,
  Carousels,
  PrintTable,
} from "./Components";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./Utils/ProtectedRoute";
import Logout from "./Utils/Logout";
import { BlogCategory, Blogposts, Jobposts, JobCategory,  NotFound, WelcomeMessage, ChatDashBoard,Contacts,Leeds, Chatheader } from "./Pages";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<StaffLogin />} />

        {/* Protected Routes */}
        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route path="/dashboard/masters/jobposts" element={<Jobposts />} />
            <Route path="/dashboard/masters/blogcategories" element={<BlogCategory />} />
            <Route path="/dashboard/masters/jobcategories" element={<JobCategory />} />
            <Route path="/dashboard/masters/blogposts" element={<Blogposts />} />
            <Route path="/dashboard/masters/WecomeMessage" element={<WelcomeMessage />} />
            <Route path="/dashboard/masters/chatbot" element={<ChatDashBoard />} />
            <Route path="/dashboard/masters/contacts" element={<Contacts />} />
            <Route path="/dashboard/masters/Leeds" element={<Leeds />} />
            <Route path="/dashboard/masters/chatheader" element={<Chatheader />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
