import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal } from "../Components";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import { MarketingNavbarComponent } from "../Components/navbar/marketingnav";
import { BiSearch } from "react-icons/bi";
import axiosConfig from "../Service/axiosConfig"
import { FormParser } from "../Components";
import Modal from 'react-bootstrap/Modal';
import Masters from "../Components/masters.json";
import DataTable from 'react-data-table-component';
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { FaHandHoldingHeart } from "react-icons/fa";
import useDebouncedValue from "../Utils/Debounce";

const Jobposts = () => {
    const [search, SetSearch] = useState({
        search: ""
    })
    const debouncedSearchTerm = useDebouncedValue(search.search, 500);
    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
    }
    const MastersModal = Masters.JobPost
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
    const [Error, SetError] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("");
    const [Data, SetData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [RowId, SetRowId] = useState()
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [popupType, setpopupType] = useState("")
    const [show, setShow] = useState(false);
    const [modalTitle, setModalTitle] = useState("Job Posts");
    const [message, setMessage] = useState('')
    const [formData, setFormData] = useState({
    });

    const handleShow = () => {
        setShow(true)
        // setSelectedImage("")
        setFormData({
            
        });
        setBlogid(null);
        setModalTitle("Job Posts");
    };
    const handleClose = () => {
        setShow(false)
        SetError("")
        setpopupType("")
    };
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`/careers/job-posts/?page=${currentPage}&page_size=${RowPerPage}&ordering=${direction === "asc" ? column.selector : -column.selector}`).then(res => {
            SetData(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };
    const [passwordError, setPasswordError] = useState("");
    const formSubmit = (FormData, id) => {
        console.log(FormData, "formdata");
        const headers = {
            'Content-Type': 'application/json'
        };
    
        if (FormData.id) {
            axiosConfig.patch(`/careers/job-posts/${FormData.id}/`, FormData, { headers })
                .then(res => {
                    console.log(res);
                    setpopupType("success");
                    setMessage("Job Post Edited successfully");
                    setPasswordError("");
                    GetData();
                    setShow(false);
                }).catch(error => {
                    console.log(error);
                    let error_message = error.response.data;
                    Object.values(error_message) &&
                    SetError(Object.values(error_message));
                    setpopupType("error");
                });
        } else {
            axiosConfig.post("/careers/job-posts/", FormData, { headers })
                .then(res => {
                    console.log(res);
                    setpopupType("success");
                    setPasswordError("");
                    setMessage("Job Post Added successfully");
                    GetData();
                    setShow(false);
                }).catch(error => {
                    console.log(error);
                    let error_message = error.response.data.job_id;
                    Object.values(error_message) &&
                    SetError(Object.values(error_message));
                    setpopupType("error");
                });
        }
    };
    
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/careers/job-posts/?page=${currentPage}&page_size=${RowPerPage}`)
            SetData(responce.data.results)
            setLoading(false)
            setTotalRows(responce.data.count)
        }
        catch (error) {
            console.log(error)
        }

    }
    const tableCustomStyles = {
        rows: {
            style: {
            },
        },
        cells: {
            style: {
                justifyContent: "center"
            },
        },
        headCells: {
            style: {
                color: "#5C5C5C",
                justifyContent: "center",
                color: "rgb(33,37,41)",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "28px",
            },
        },
    };
    useEffect(() => {
        const getSearchResults = async () => {
            axiosConfig.get(`/careers/job-posts/?search=${debouncedSearchTerm}`).then(res => {
                SetData(res.data.results)
            }).catch(error => {
                console.log(error)
            })
        };
        getSearchResults();
    }, [debouncedSearchTerm]);
    useEffect(() => {
        GetData();
    }, [currentPage, RowPerPage]);
    const [blogid, setBlogid] = useState()
    function convertDate(dateString) {
        // Create a new Date object from the input string
        const date = new Date(dateString);
        
        // Extract the year, month, and day components
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
        const day = String(date.getDate()).padStart(2, '0');
        
        // Format the date components into YYYY-MM-DD
        return `${year}-${month}-${day}`;
    }
    const blogedit = (id) => {
        axiosConfig.get(`/careers/job-posts/${id}/`).then(res => {
            console.log(res.data, "edit")
            setFormData({...res.data,category:res.data?.category?.id,posted_date:convertDate(res.data?.posted_date),deadline:convertDate(res.data?.deadline)})
            setBlogid(id)
            setShow(true)
        }).catch(error => {
            console.log(error)
        })
    }
    const columns = [
        {
            name: "S.No",
            selector: "id",
            cell: (row, i) => (currentPage - 1) * RowPerPage + i + 1,
            sortable: true
        },
        {
            name: "job_id",
            selector: "job_id",
            cell: row => row.job_id,
            sortable: true
        },

        {
            name: "title",
            selector: "title",
            cell: row => row.title,
            sortable: true
        },
        {
            name: "category",
            selector: "category",
            cell: row => row.category?.category,
            sortable: true
        },
        {
            name: "location",
            selector: "location",
            cell: row => row.location,
            sortable: true
        },
        {
            name: "no of positions",
            selector: "no_of_positions",
            cell: row => row.no_of_positions,
            sortable: true
        },
        {
            name: "applications",
            selector: "applications",
            cell: row => row.applications,
            sortable: true
        },
        {
            name: "posted date",
            selector: "posted_date",
            cell: row => row.posted_date,
            sortable: true
        },
        {
            name: "deadline",
            selector: "deadline",
            cell: row => row.deadline,
            sortable: true
        },
        {
            name: "ACTIONS",
            selector: row => {
                const HandleEdit = async () => {
                    console.log(row.id, 'iddddd')
                    // setShow(true)
                    blogedit(row.id)
                    // handleShow(row.id);
                    setModalTitle("Edit Job Posts");
                }
                const DeleteAlert = () => {
                    SetRowId(row.id)
                    SetAlertDeletePopupType("success")
                }
                const CancelAlerrt = () => {
                    SetAlertDeletePopupType("error")
                }
                const HandleDelete = async (id) => {
                    await axiosConfig.delete(`/careers/job-posts/${id}/`).then(() => {
                        SetAlertDeletePopupType("error")
                        setDeletepopupType("success")
                        GetData();
                    }).catch((error) => {
                        setDeletepopupType("error")
                        SetAlertDeletePopupType("error")
                        console.log(error)
                    })
                }
                const handleRevive = async () => {
                    try {
                        const response = await axiosConfig.post(`/careers/job-posts/${row.id}/revive/`)
                        setpopupType("success")
                        setMessage("JobPost revived successfully")
                        GetData()
                    } catch (error) {
                        console.log("Error While Reviving", error)
                    }
                }
                return (
                    <>
                        {!row.is_suspended ?
                            <div className="row" >
                                <div className="col-4">
                                    <MdOutlineEdit className="dashboard-tabe-data-actions" onClick={HandleEdit} />
                                </div>
                                <div className="col-4">
                                    <MdDeleteOutline className="dashboard-tabe-data-actions" onClick={DeleteAlert} />
                                </div>
                            </div>
                            :
                            <>
                                <FaHandHoldingHeart className="dashboard-tabe-data-actions" onClick={handleRevive} />
                            </>
                        }
                        {
                            AlertDeletePopupType === "success" &&
                            <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
                        }
                    </>
                )

            }
        }
    ]
    return (
        <>
            <div className="child-sidenav">
                <MarketingNavbarComponent />
                <div className="childnav-content">
                <div className="d-flex align-items-center">
            <div className="input-cat-with-icon">
              <span className="icon-product">
                <BiSearch />
              </span>
              <input
                type="text"
                className="input-control"
                placeholder="Search"
                name="search"
                value={search.search}
                onChange={HandleSearch}
              />
            </div>
            <div className="cat-button">
              <div className="btn" onClick={handleShow}>
                + Add New
              </div>
            </div>
          </div>
                    <div className="table-responsive marginTop">
                        <DataTable columns={columns} data={Data} pagination paginationTotalRows={totalRows}
                            paginationPerPage={RowPerPage}
                            paginationDefaultPage={currentPage}
                            onChangePage={(page) => setCurrentPage(page)}
                            paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                            onSort={handleSort}
                            paginationServer fixedHeader subHeader customStyles={tableCustomStyles} progressPending={loading}
                            progressComponent={<div className="loader-container">
                                <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                            </div>}
                        />
                    </div>
                    <div>
                        <Modal
                            size="xl"
                            show={show}
                            onHide={handleClose}
                            aria-labelledby="example-modal-sizes-title-lg"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg">
                                    {modalTitle}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {passwordError && (
                                    <div className="alert alert-danger" role="alert">
                                        {passwordError}
                                    </div>
                                )}

                                <FormParser modelObject={MastersModal} formData={formData} formSubmit={formSubmit} error={Error} buttonTitle='Save' />
                            </Modal.Body>
                        </Modal>
                        {popupType !== "" &&
                            <PopupModal setpopupType={setpopupType} popupType={popupType} Navigation="" Message={message} />
                        }
                        {deletePopupType !== "" &&
                            <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} />
                        }
                    </div>

                </div>
            </div>
        </>
    )
}
export { Jobposts }