import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal } from "../Components";
import { MdOutlineEdit } from "react-icons/md"
import { FormParser } from "../Components";
import Masters from "../Components/masters.json";
import { FileManager } from "../Components/FileManager";
import { Modal } from 'react-bootstrap';
import axiosConfig from "../Service/axiosConfig";
import { MarketingNavbarComponent } from "../Components/navbar";

const Chatheader = () => {
    const MastersModal = Masters.ChatHeader;
    const [readOnly, setReadOnly] = useState(true);
    const [show, setShow] = useState(false);
    const [Error, SetError] = useState("");
    const [popupType, setpopupType] = useState("");
    const [paymentGateway, setPaymentGateway] = useState([])
    const [message, setMessage] = useState("");
    const handleShow = () => {
        setShow(true)
    }

    const GetPaymentGateway = async () => {
        try {
            const responce = await axiosConfig.get(`/message_framework/chatlogo/`)
            console.log(responce)
            if (responce.data.results.length > 0) {
                setPaymentGateway(responce.data.results[0])
            }
        }
        catch (error) {
            console.log(error)
        }

    }


    const formSubmit = async (Data) => {
        try {
            // Prepare FormData if "logo" is a file
            let formData;
            if (typeof Data.logo !== "string") {
                formData = new FormData();
                formData.append("logo", Data.logo);
                formData.append("title", Data.title);
                formData.append("sub_title", Data.sub_title);
            } else {
                // If "logo" is a string, send it directly with other data
                formData = {
                    title: Data.title,
                    sub_title: Data.sub_title
                };
            }
    
            // Determine whether to update or create based on Data.id
            if (Data.id) {
                const response = await axiosConfig.patch(`/message_framework/chatlogo/${Data.id}/`, formData, {
                    headers: formData instanceof FormData ? { 'Content-Type': 'multipart/form-data' } : {}
                });
                console.log("Updated successfully:", response);
                setMessage("Updated successfully");
            } else {
                const response = await axiosConfig.post(`/message_framework/chatlogo/`, formData, {
                    headers: formData instanceof FormData ? { 'Content-Type': 'multipart/form-data' } : {}
                });
                console.log("Created successfully:", response);
                setMessage("Created successfully");
            }
    
            setpopupType("success");
        } catch (error) {
            console.log("Error setting the site data:", error);
            setpopupType("error");
        }
    };
    

    useEffect(() => {
        GetPaymentGateway();
    }, [])

    return (
        <>
            <div className="child-sidenav">
                <MarketingNavbarComponent />
                <div className="childnav-content">
                    <FormParser
                        modelObject={MastersModal}
                        buttonTitle="Save"
                        readOnly={readOnly}
                        setReadOnly={setReadOnly}
                        formData={paymentGateway}
                        formSubmit={formSubmit}
                        error={Error}
                    />
                    {popupType != "" &&
                        <PopupModal popupType={popupType} setpopupType={setpopupType} Navigation="" Message={message} />
                    }
                    <Modal
                        size="xl"
                        show={show}
                        onHide={() => setShow(false)}
                        aria-labelledby="example-modal-sizes-title-lg"
                        className="fm-modal"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-lg" className="fm-modal-title">
                                Choose images
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="fm-modal-body">
                            <FileManager />
                        </Modal.Body>
                    </Modal>
                    {readOnly &&
                        <button className={`btn btn-primary d-flex align-items-center`} onClick={() => setReadOnly(false)}>
                            <div>Edit</div>
                            <MdOutlineEdit className="ms-5" />
                        </button>
                    }
                </div>
            </div>
        </>
    )
}
export { Chatheader }