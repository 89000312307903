import { AiTwotoneHome } from 'react-icons/ai';
import { GiEarthAmerica } from 'react-icons/gi';
import { TbBuildingEstate } from 'react-icons/tb';
import { MdLocationCity } from 'react-icons/md';
import { MdAddLocation } from 'react-icons/md';
import { BsFillBarChartLineFill } from 'react-icons/bs';
import { MdAssignment } from 'react-icons/md';
import { MdLowPriority } from 'react-icons/md';
import { MdOutlineCallMerge } from 'react-icons/md';
import { BsFillCreditCardFill } from 'react-icons/bs';
import { MdViewModule } from 'react-icons/md';
import { MdOutlineQueuePlayNext } from 'react-icons/md';
import { FaUserLock,FaProcedures } from 'react-icons/fa';
import { ImUserCheck } from 'react-icons/im';
import { BsFillGrid3X2GapFill } from 'react-icons/bs';
import { IoIosPeople } from 'react-icons/io';
import { MdAddToQueue } from 'react-icons/md';
import { MdDomain } from 'react-icons/md';
import { MdDomainAdd } from 'react-icons/md';
import { FaFileMedical } from 'react-icons/fa';
import { FaUpload } from 'react-icons/fa'
import { TbReport } from 'react-icons/tb'
import { MdDomainDisabled,MdPendingActions,MdMeetingRoom,MdMedicalServices,MdListAlt,MdHistory } from 'react-icons/md'
import { RiBillFill,RiFolderInfoFill } from 'react-icons/ri'
import {FaUserAlt} from "react-icons/fa"
import {FaGlobe} from "react-icons/fa"
import {FaSafari} from "react-icons/fa"
import {MdSpaceDashboard,MdInventory2,MdAppRegistration} from "react-icons/md"
import {FaCommentAlt,FaFileInvoice} from "react-icons/fa"
import {GrCompliance} from "react-icons/gr"
import {FaClinicMedical,FaUserClock,FaBookMedical,FaUserMd} from "react-icons/fa"
import {SiLinuxcontainers} from "react-icons/si"
import {SiProcessingfoundation,SiAdobepremierepro} from "react-icons/si"
import {MdMedicationLiquid,MdLocationOn,MdFolderSpecial,MdBrowseGallery,MdOutlineMedicalInformation} from "react-icons/md"
import {BiClinic} from "react-icons/bi"
import {FaRegMoneyBillAlt} from "react-icons/fa"
import { useEffect } from 'react';

let newDate = new Date()
let Newmonth = newDate.getMonth()
if(Newmonth <= 9){
    Newmonth = "0"+Newmonth
}
let year = newDate.getFullYear()
  let data= localStorage.getItem("user_data")

const sidebarData = {
    "dashboard" : [
        {
            "SubTitle":"Dashboard",
            "NavItems":[
                {"label":"Dashboard","path":"/dashboard/users","icon":<IoIosPeople fontSize='small'/>}
            ]
        }
    ],
    // "patient_profile": [
    //     {
    //         "SubTitle":"Patient Profile",
    //         "NavItems":[
    //             {"label":"Info","path":"/dashboard/patients/info","icon":<MdOutlineMedicalInformation fontSize='small'/>},
    //             {"label":"Invoices","path":"/dashboard/patients/invoices","icon":<FaFileInvoice fontSize='small'/>},
    //             {"label":"Records","path":"/dashboard/patients/records","icon":<MdListAlt fontSize='small'/>},
    //             {"label":"Appointments","path":"/dashboard/patients/appointments","icon":<MdMeetingRoom fontSize='small'/>},
    //             {"label":"Visit History","path":"/dashboard/patients/visit_history","icon":<MdHistory fontSize='small'/>},
    //         ]
    //     }
    // ],
    "EMR": [
        {
            "NavItems":[
                {"label":"Vitals","name":"vitals","path":"#","icon":<MdAppRegistration fontSize='small'/>},
                {"label":"Complaints","name":"complaints","path":"#","icon":<IoIosPeople fontSize='small'/>},
                {"label":"Diagnosis","name":"diagnosis","path":"#","icon":<IoIosPeople fontSize='small'/>},
                {"label":"Rx","name":"rx","path":"#","icon":<IoIosPeople fontSize='small'/>},
                {"label":"Advice","name":"advice","path":"#","icon":<IoIosPeople fontSize='small'/>},
                {"label":"Test Requested","name":"investigations_ids","path":"#","icon":<IoIosPeople fontSize='small'/>},
                {"label":"Past Visits","name":"Past Visits","path":"#","icon":<IoIosPeople fontSize='small'/>},
                {"label":"Records","path":"#", "name":"Records","icon":<IoIosPeople fontSize='small'/>}
            ]
        }
    ],
    "qms": [
        {
            "SubTitle":"Appointment Scheduling",
            "NavItems":[
                {"label":"Info","path":"/dashboard/qms/Pataient","icon":<MdAppRegistration fontSize='small'/>},
                {"label":"Appointments","path":"/dashboard/qms/calander","icon":<IoIosPeople fontSize='small'/>},
                {"label":"My Appointments","path":"/dashboard/qms/appointments","icon":<IoIosPeople fontSize='small'/>},
                {"label":"Prescription","path":"#","icon":<IoIosPeople fontSize='small'/>}
            ]
        }
    ],
    "billing": [
        {
            "SubTitle":"Billing",
            "NavItems":[
                // {"label":"Case Upload","path":"/dashboard/billing/upload-cases","icon":<FaUpload fontSize='small'/>},
                {"label":"Bills","path":"#","icon":<FaUpload fontSize='small'/>},
                {"label":"Invoices","path":"#","icon":<FaUpload fontSize='small'/>},
            ]
            
        },
        // {
        //     "SubTitle":"Client Billed Services",
        //     "NavItems":[
        //         {"label":"Client Bill","path":`/dashboard/billing/client-bills/?month=${year}-${Newmonth}`,"icon":<RiBillFill fontSize='small'/>},
        //         {"label":"Domestic","path":"/dashboard/billing/domestic","icon":<FaUserAlt fontSize='small'/>},
        //         {"label":"International","path":"/dashboard/billing/international","icon":<FaGlobe fontSize='small'/>},
        //         {"label":"ARI","path":"/dashboard/billing/ari","icon":<FaSafari fontSize='small'/>},
        //     ]
        // },
        //  {
        //      "SubTitle":"Final Bill",
        //      "NavItems":[
        //          {"label":"Invoices","path":`/dashboard/billing/client-final-bills/?month=${year}-${Newmonth}`,"icon":<RiBillFill fontSize='small'/>}
        //      ]
        //  },
    ],

    "reports": [
        {
            "SubTitle":"Reports",
            "NavItems":[
                {"label":"Appointments","path":"#","icon":<IoIosPeople fontSize='small'/>},
                {"label":"Fees","path":"#","icon":<IoIosPeople fontSize='small'/>},
                {"label":"Prescription","path":"#","icon":<IoIosPeople fontSize='small'/>}
            ]
        }
    ],
    "DoctorProfile": [
        {
            "SubTitle":"Doctor Profile",
            "NavItems":[
                {"label":"Info","path":`/dashboard/profile`},
                {"label":"Specialites","path":`/dashboard/profile/specialities`},
                {"label":"Availability","path":`/dashboard/profile/doctor_availability`},
                {"label":"Vacations","path":`/dashboard/profile/vacations`}
            ]
        }
    ],
    "clinicProfile": [
        {
            "SubTitle":"Clinics",
            "NavItems":[
                {"label":"Clinic","path":`/dashboard/clinic/`,"icon":<IoIosPeople fontSize='small'/>},
                {"label":"Clinics Specialites","path":`/dashboard/clinic/specialities`,"icon":<IoIosPeople fontSize='small'/>},
                {"label":"Clinics Availability","path":`/dashboard/clinics/clinic_availability`,"icon":<IoIosPeople fontSize='small'/>},
                {"label":"Clinics Gallery","path":`/dashboard/clinic/gallery`,"icon":<IoIosPeople fontSize='small'/>},
                {"label":"Clinics Vacations","path":`/dashboard/clinics/clinic_vacations`,"icon":<IoIosPeople fontSize='small'/>}
            ]
        }
    ],

    "accounts" : [
        {
            "SubTitle":"Clinics/Doctors",
            "NavItems":[
                {"label":"Clinics","path":"/dashboard/profile/clinics","icon":<BiClinic fontSize='small'/>},
                // {"label":"users","path":"/dashboard/users","icon":<IoIosPeople fontSize='small'/>},
                {"label":"Doctor","path":"/dashboard/profile","icon":<FaUserMd fontSize='small'/>},
                // {"label":"User Module Permission","path":"/dashboard/users","icon":<FaUserLock fontSize='small'/>},
                // {"label":"radiologist","path":"/dashboard/radiologist","icon":<FaFileMedical fontSize='small'/>}
            ]
        },
        // {
        //     "SubTitle":"Doctor",
        //     "NavItems":[
        //         {"label":"Registration","path":"/dashboard/profile","icon":<FaUserMd fontSize='small'/>},
        //         {"label":"Speciality","path":"/dashboard/users/speciality","icon":<FaUserMd fontSize='small'/>},
        //         {"label":"Availability","path":"/dashboard/users/availability","icon":<FaUserMd fontSize='small'/>},
        //         {"label":"Vacation","path":"/dashboard/users/vacation","icon":<FaUserMd fontSize='small'/>},
        //     ]
        // }
    ],
    "masters": [
        {
            "SubTitle":"References",
            "NavItems":[
                {"label":"Domain","path":"/dashboard/masters/domains","icon":<MdDomain fontSize="small" className=' configiration-icon'/>},
                {"label":"ReferenceDomain Value","path":"/dashboard/masters/domains/value","icon":<MdDomainAdd fontSize='small' className=' configiration-icon'/>}
            ]
        },
        {
            "SubTitle":"Locations",
            "NavItems":[
                {"label":"Country","path":"/dashboard/masters/countries","icon":<GiEarthAmerica fontSize='small' className=' configiration-icon'/>},
                {"label":"State","path":"/dashboard/masters/states","icon":<TbBuildingEstate fontSize='small' className=' configiration-icon'/>},
                {"label":"City","path":"/dashboard/masters/cities","icon":<MdLocationCity fontSize='small' className=' configiration-icon'/>},
                {"label":"Area","path":"/dashboard/masters/areas","icon":<AiTwotoneHome fontSize='small' className=' configiration-icon'/>}
            ]
        },
        {
            "SubTitle":"Configurations",
            "NavItems":[
                // {"label":"Department","path":"/dashboard/masters/departments","icon":<MdAddLocation fontSize='small' />},
                // {"label":"Sub Department","path":"/dashboard/masters/sub-departments","icon":<MdAddToQueue fontSize='small' />},
                {"label":"Services","path":"/dashboard/masters/services","icon":<BsFillBarChartLineFill fontSize='small' />},
                // {"label":"Bill Head","path":"/dashboard/masters/billhead","icon":<MdAssignment fontSize='small' />},
                // {"label":"Priority","path":"/dashboard/masters/priority","icon":<MdLowPriority fontSize='small'  />},
                // {"label":"TariffType","path":"/dashboard/masters/tarifftype","icon":<MdOutlineCallMerge fontSize='small' />},
                // {"label":"Tariff Card","path":"/dashboard/masters/tariffcard","icon":<BsFillCreditCardFill fontSize='small' />},
                // {"label":"Module","path":"/dashboard/masters/module","icon":<MdViewModule fontSize='small' />},
                {"label":"Specialities","path":"/dashboard/masters/specialities","icon":<MdSpaceDashboard fontSize='small' />},
                {"label":"Facilities ","path":"/dashboard/masters/facilities ","icon":<FaCommentAlt fontSize='small' />},
                {"label":"Complaints ","path":"/dashboard/masters/complaints ","icon":<GrCompliance fontSize='small' />},
                {"label":"Clinical Findings","path":"/dashboard/masters/clinicalfindings","icon":<FaClinicMedical fontSize='small' />},
                {"label":"Conditions","path":"/dashboard/masters/condations","icon":<SiLinuxcontainers fontSize='small' />},
                {"label":"Procedure","path":"/dashboard/masters/procedure","icon":<SiProcessingfoundation fontSize='small' />},
                {"label":"Medication","path":"/dashboard/masters/medication","icon":<MdMedicationLiquid fontSize='small' />},
                {"label":"Provisional Diagnosis","path":"/dashboard/masters/provisinol-dignosis","icon":<SiAdobepremierepro fontSize='small' />},
                {"label":"Investigations","path":"/dashboard/masters/investigations","icon":<MdInventory2 fontSize='small' />},
                // {"label":"Module Screen","path":"/dashboard/masters/modulescreen","icon":<MdOutlineQueuePlayNext fontSize='small' />},
                // {"label":"Module Permission","path":"/dashboard/masters/modulepermission","icon":<FaUserLock fontSize='small' />},
                // {"label":"Role","path":"/dashboard/masters/role","icon":<ImUserCheck fontSize='small' />},
                // {"label":"Role Module Permission","path":"/dashboard/masters/rolemodulepermission","icon":<BsFillGrid3X2GapFill fontSize='small' />}
            ]
        },
        
        
    ]
}
export default sidebarData;