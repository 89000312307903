import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { MdOutlineCategory } from "react-icons/md"
import { BsFilePost } from "react-icons/bs"
import { MdOutlineControlPointDuplicate } from "react-icons/md";
import { LuFileEdit } from "react-icons/lu"
import { BiCategory } from "react-icons/bi";
import { SiChatbot } from "react-icons/si";
import "../../Pages/master_categories.css"
import "../../Pages/Pages.css"
import { MdMessage, MdContacts } from "react-icons/md";
const MarketingNavbarComponent = () => {
    const [isOpen, setIsOpen] = useState(true);

    const [isChatOpen, setIsChatOpen] = useState(true);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const toggleChatDropdown = () => {
        setIsChatOpen(!isChatOpen);
    };
    return (
        <div className="categorie-header">
            <NavLink to="/dashboard/masters/blogcategories" className="categorie-subtitle" ><div className="child-title">
                <MdOutlineCategory size={24} className="childnav-icon" />
                <p className="childnav-name">Blog Categories</p>
            </div></NavLink>
            <li className="theme-doc-sidebar-item-category theme-doc-sidebar-item-category-level-1 menu__list-item" style={{ listStyle: "none" }}>
                <div className="menu__list-item-collapsible">
                    <a
                        href="#"
                        className={`menu__link menu__link--sublist menu__link--sublist-caret ${isChatOpen ? 'open' : ''}`}
                        aria-expanded={isChatOpen}
                        onClick={toggleChatDropdown}
                    >
                        <span className="child-title"><SiChatbot size={24} className="childnav-icon" />ChatBot</span>
                    </a>
                    <ul className={`${isChatOpen ? 'show' : ''}`}>
                        <li>
                            <NavLink className="dropdown-item childnav-name" to="/dashboard/masters/WecomeMessage" activeClassName="active">Welcome Message</NavLink></li>
                        <li><NavLink className="dropdown-item childnav-name" to="/dashboard/masters/chatbot" activeClassName="active">ChatBot</NavLink></li>
                        <li><NavLink className="dropdown-item childnav-name" to="/dashboard/masters/chatheader" >Chat Header</NavLink></li>
                    </ul>
                </div>
            </li>
            <NavLink to="/dashboard/masters/blogposts" className="categorie-subtitle" ><div className="child-title">
                <BsFilePost size={24} className="childnav-icon" />
                <p className="childnav-name">Blog Posts</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/jobcategories" className="categorie-subtitle" ><div className="child-title">
                <MdOutlineControlPointDuplicate size={24} className="childnav-icon" />
                <p className="childnav-name">Job Categories</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/contacts" className="categorie-subtitle" ><div className="child-title">
                <MdContacts size={24} className="childnav-icon" />
                <p className="childnav-name">Contacts</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/Leeds" className="categorie-subtitle" ><div className="child-title">
                <LuFileEdit size={24} className="childnav-icon" />
                <p className="childnav-name">Leeds</p>
            </div></NavLink>
        </div>
    )
}

export { MarketingNavbarComponent }
