import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import { DashboardLayout } from './DashboardLayout';
import axiosConfig from "../../Service/axiosConfig";
import DataTable from 'react-data-table-component';
import tableCustomStyles from "../PopupModal/tableCustomStyles";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ChartTable = () => {
  let today_date = (new Date()).toISOString().split('T')[0];
  const [formData, setFormData] = useState({
    fromDate: today_date,
    toDate: today_date,
  });
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [RowPerPage, setRowPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentProductPage, setCurrentProductPage] = useState(1);
  const [totalProductRows, setTotalProductRows] = useState(0);

  const formatDate = (inputDate) => {
    const dateObject = new Date(inputDate);

    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    const formattedDate = dateObject.toLocaleDateString('en-GB', options);

    return formattedDate
  }

  const option = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      title: {
        display: true,
        text: "Sales per Month",
      },
    },
  };

  const client_columns = [
    {
      name: 'S No.',
      cell: (row, index) => <div>{index + 1}</div>,
      sortable: true,
    },
    {
      name: 'User',
      selector: 'user_name',
      sortable: true,
    },
    {
      name: 'Total Amount',
      selector: 'total_net_amount',
      sortable: true,
    },
    {
      name: 'Total Quantity',
      selector: 'total_quantity',
      sortable: true,
    },
  ]

  const product_columns = [
    {
      name: 'S No.',
      cell: (row, index) => <div>{index + 1}</div>,
      sortable: true,
    },
    {
      name: 'Product',
      selector: 'product_name',
      sortable: true,
    },
    {
      name: 'Quantity',
      selector: 'total_quantity_product',
      sortable: true,
    },
  ]

  const client_data = chartData ? chartData.user_todays_delivered : [];

  const product_data = chartData ? chartData.product_todays_deliverd : [];

  const labels = chartData.net_amount_per_day ? Object.keys(chartData.net_amount_per_day).map((date) => formatDate(date)) : [];

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Sales per Day",
        data: chartData.net_amount_per_day ? Object.values(chartData.net_amount_per_day) : [],
        backgroundColor: "#3eaa49",
      },
    ],
  };

  const ClientTableHeading = () => {
    return (
      <div>User-wise Today's orders</div>
    )
  }

  const ProductTableHeading = () => {
    return (
      <div>Product-wise Today's orders</div>
    )
  }

  const GetData = async () => {
    try {
      const response = await axiosConfig.get("/accounts/display_data_analysis/");
      console.log(response.data, "chart data");
      setChartData(response.data)
      setTotalRows(response.data.client_todays_delivered.length)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    GetData();
  }, [])

  return (
    <>
      <div>
        <div className='cards-container'>
          <div className="filter-card">
            <div className='f_24_400'>Rs. {chartData.active_orders ? chartData.active_orders : 0}</div>
            <div>Active Orders</div>
          </div>
          <div className="filter-card">
            <div className='f_24_400'>Rs. {chartData.deliverd_orders ? chartData.deliverd_orders : 0}</div>
            <div>Delivered Orders</div>
          </div>
          <div className="filter-card">
            <div className='f_24_400'>Rs. {chartData.month_average ? chartData.month_average : 0}</div>
            <div>Month Average</div>
          </div>
          <div className="filter-card">
            <div className='f_24_400'>Rs. {chartData.month_lowest ? chartData.month_lowest : 0}</div>
            <div>Month Lowest</div>
          </div>
          <div className="filter-card">
            <div className='f_24_400'>Rs. {chartData.month_highest ? chartData.month_highest : '00000000'}</div>
            <div>Month Highest</div>
          </div>
        </div>
        <Bar options={option} data={data} />
        <DataTable
          columns={client_columns}
          data={client_data}
          pagination
          title={<ClientTableHeading />}
          paginationTotalRows={totalRows}
          // paginationPerPage={RowPerPage}
          // paginationDefaultPage={currentPage}
          onChangePage={(page) => setCurrentPage(page)}
          paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
          paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
          // onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
          // onSort={handleSort}
          // paginationServer
          customStyles={tableCustomStyles}
          progressPending={loading}
          progressComponent={<div className="loader-container">
            <img src={require('../FormParser/images/loader2.gif')} alt="Loading..." />
          </div>}
        />
        <DataTable
          columns={product_columns}
          data={product_data}
          pagination
          title={<ProductTableHeading />}
          paginationTotalRows={totalProductRows}
          // paginationPerPage={RowPerPage}
          // paginationDefaultPage={currentPage}
          onChangePage={(page) => setCurrentProductPage(page)}
          paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
          paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
          // onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
          // onSort={handleSort}
          // paginationServer
          customStyles={tableCustomStyles}
          progressPending={loading}
          progressComponent={<div className="loader-container">
            <img src={require('../FormParser/images/loader2.gif')} alt="Loading..." />
          </div>}
        />
      </div>
    </>
  )


};

export default ChartTable;
