import React, { useState,useEffect } from 'react';
import { RxCross2 } from "react-icons/rx"
import { AiOutlinePlus } from "react-icons/ai"
const MultiFilemanager = ({Handlefilemanager,formData,setFormData}) => {
    const handleImageDrop = (e) => {
        e.preventDefault();
        const sourceIndex = e.dataTransfer.getData("imageIndex");
        const destinationIndex = 0; // Assuming you want to set the dragged image as the main image
        const imagesCopy = [...formData.image_key];

        // Swap the images
        const temp = imagesCopy[destinationIndex];
        imagesCopy[destinationIndex] = imagesCopy[sourceIndex];
        imagesCopy[sourceIndex] = temp;

        setFormData&& setFormData({
            ...formData,
            image_key: imagesCopy,
        });
    };
    const handleImageDragStart = (e, index) => {
        e.dataTransfer.setData("imageIndex", index);
    };
    const removeImageByIndex = (indexToRemove) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            image_key: prevFormData.image_key.filter((_, index) => index !== indexToRemove),
        }));
    };
    return (
        <div>
            <div className="flex-container">
                {formData.image_key && formData.image_key.length > 0 && (
                    <div className="image-field">
                        <div
                            onDrop={(e) => handleImageDrop(e)}
                            onDragOver={(e) => e.preventDefault()}
                        >
                            <img
                                className="image-item"
                                src={
                                    formData.image_key[0] && formData.image_key[0].image
                                        ? formData.image_key[0].image
                                        : ""
                                }
                                alt={
                                    formData.image_key[0] && formData.image_key[0].image
                                        ? formData.image_key[0].image
                                        : "images"
                                }
                            />
                        </div>
                    </div>
                )}
                <div className="">

                    {formData.image_key && formData.image_key.length > 0 && (
                        <div className="image-grid">
                            {formData.image_key.map((image, index) => {
                                // Add a null check for 'image' property
                                const imageUrl = image && image.image ? image.image : '';

                                return (
                                    <div className="flex-item-image" key={index} draggable onDragStart={(e) => handleImageDragStart(e, index)}>
                                        <div>
                                            <div className="image-container">
                                                <img src={imageUrl} alt={`Image ${index + 1}`} className="grid-item" />
                                                <RxCross2 onClick={() => removeImageByIndex(index)} className="cross-icon" />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}

                    <div>
                        <div className="upload-multiple" onClick={()=>Handlefilemanager("image_key",true)}>
                            <div className="upload-plus">
                                <label htmlFor="imageInput1">
                                    <AiOutlinePlus className="upload-width" />
                                </label>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { MultiFilemanager };
