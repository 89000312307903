import React, { useEffect, useRef, useState } from "react";
import './FileManager.css';
import { AiOutlineInfoCircle, AiOutlineSearch, AiOutlineFolderAdd } from "react-icons/ai";
import { BsSortDownAlt } from "react-icons/bs";
import { TfiFolder } from "react-icons/tfi"
import ProgressBar from 'react-bootstrap/ProgressBar';
import folderIcon from './images/folder icon.svg';
import Modal from 'react-bootstrap/Modal';
import Toast from 'react-bootstrap/Toast';
import Button from 'react-bootstrap/Button';
import { FiLoader } from "react-icons/fi"
import { TiTick } from "react-icons/ti"
import { Dropdown, Spinner } from 'react-bootstrap';
import axiosConfig from "../../Service/axiosConfig"
import { HiOutlineChevronRight } from "react-icons/hi"
import { FiMoreVertical } from 'react-icons/fi';
import Folder from "./Folder";
import { Pagination } from "../PopupModal/Pagination";
const FileManager = ({ modalShow, setModalShow, setFormData, file_name, handleFile, formData, value, ismultiple }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('');
    const [activeImg, setActiveImg] = useState();
    const [lgShow, setLgShow] = useState(false);
    const [loading, setLoading] = useState(false)
    const [loading1, setLoading1] = useState(false)
    const [activestate, setActivestate] = useState([])
    const [ParentFolder, setParentFolder] = useState("")
    const [Folders, setfolders] = useState([])
    const [Items, setItems] = useState([])
    const [is_root, setIsRoot] = useState(true)
    const [Data, setData] = useState([])
    const [originalData, setOriginalData] = useState([])
    const [newFolderName, setNewFolderName] = useState('');
    const [isCreatingFolder, setIsCreatingFolder] = useState(false);
    const [isHovered, setHovered] = useState(false);
    const [Error, setError] = useState("")
    const [RowPerPage, setRowPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPagefolder, setCurrentPageFolder] = useState(1);
    const [NextPage, setNexTPage] = useState(null);
    const [NextPage1, setNexTPage1] = useState(null);
    const [Previous, setPrevious] = useState(null);
    const [Previous1, setPrevious1] = useState(null);
    const [search, setSearch] = useState("");
    const [images, setimages] = useState([])
    const [bredCrumbs, setBredcrumbs] = useState([{
        id: "",
        name: "Files"
    }])
    const handleFileChange = (event) => {
        setLoading(true);
        const inputFiles = event.target.files;

        // Convert the FileList to an array
        const fileArray = Array.from(inputFiles);
        const newImageArray = fileArray.map(file => ({
            file,
        }));
        console.log(fileArray)
        setimages(fileArray);
        ImagePost({
            folder: ParentFolder ? ParentFolder : null,
            image: fileArray
        });
    };
    const handvlemodalShow = () => {
        setLgShow(true)
    }
    const handlemodalclose = () => {
        setLgShow(false)
        setModalShow(false)
        setBredcrumbs([])
    }
    const [selectedImagesForTrashing, setSelectedImagesForTrashing] = useState({
        media_item_ids: [],
    });

    const handleImgClick = async (file, ind, e) => {
        e.stopPropagation();
        if (!activestate.includes(ind)) {
            setActivestate([...activestate, ind]);
            setActiveImg(file)
            setSelectedImagesForTrashing((prev) => ({
                ...prev,
                media_item_ids: [...prev.media_item_ids, file.id],
            }));
        } else {
            setActivestate(activestate.filter((i) => i !== ind));
            setSelectedImagesForTrashing((prev) => ({
                ...prev,
                media_item_ids: prev.media_item_ids.filter((id) => id !== file.id),
            }));
        }
    }
    const[Trash,setTrash]=useState(false)
    const handleTabClick = (tab) => {
        setActiveTab(tab);
        if(tab==="Trash"){
            setIsRoot(true)
            setTrash(true)
            setParentFolder("")
        }
    };
    const handleShot = () => {
        const filteredImages = [...Folders, ...Items].filter((image, index) => activestate.includes(index));
        if (ismultiple) {
            setSelectedImagesForTrashing({
                media_item_ids: [],
            })
            setFormData(prevData => ({
                ...prevData,
                [value]: [...prevData[value], ...filteredImages],
            }));
        }
        else {
            setSelectedImagesForTrashing({
                media_item_ids: [],
            })
            handleFile && handleFile(filteredImages, file_name)
        }
        handlemodalclose();
    };
    const ImagePost = async (images) => {
        setShowToast(true);
        setLoading(true); // Set loading to true when starting the image upload

        // Create a FormData object to handle file uploads
        const formData = new FormData();
        console.log(images)
        formData.append('folder', images.folder);
        images.image.forEach((image) => {
            formData.append('image', image); // Assuming 'images' is the field name expected by your server
        });

        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';

        try {
            const response = await axiosConfig.post('/mediamanager/media-items/', formData);

            // Assuming that the response contains information about the uploaded images
            const uploadedImages = response.data;

            setLoading(false); // Set loading to false after successful upload
            folderGet();
            filesGet();
        } catch (error) {
            console.error("Check for too large : ",error);
            setError("Image may be too large please check the size")
            setLoading(false); // Set loading to false on error
        }
    };

    const [LastPageFolder, setLastPageFolder] = useState()
    const [direction, setDirection] = useState("")
    const [count, setCount] = useState()
    const folderGet = async () => {
        setLoading(true)
        try {
            await axiosConfig
                .get(`/mediamanager/media-folders/?is_root=${is_root}&parent_folder=${ParentFolder}&page_size=${RowPerPage}&page=${currentPagefolder}&search=${search}&ordering=${direction}&is_trash=${Trash}`)
                .then((res) => {
                    const totalCount = res.data.count;
                    const pageSize = RowPerPage; // Assuming your page size is 20

                    const lastPage = Math.ceil(totalCount / pageSize);
                    setLastPageFolder(lastPage)
                    setfolders(res.data.results)
                    setNexTPage(res.data.next)
                    setPrevious(res.data.previous)
                    setLoading(false)
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false)
                });
        }
        catch (error) {
            console.log(error)
        }
    };
    const folderGetNext = async () => {
        setLoading(true)
        if (NextPage) {
            try {
                await axiosConfig
                    .get(NextPage)
                    .then((res) => {
                        setfolders(res.data.results)
                        setNexTPage(res.data.next)
                        setPrevious(res.data.previous)
                        setLoading(false)
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false)
                    });
            }
            catch (error) {
                console.log(error)
            }
        }
        else {
            console.log(null, "return")
            return null
        }
    };
    const folderGetPrevious = async () => {
        setLoading(true)
        if (Previous) {
            try {
                await axiosConfig
                    .get(Previous)
                    .then((res) => {
                        setfolders(res.data.results)
                        setNexTPage(res.data.next)
                        setPrevious(res.data.previous)
                        setLoading(false)
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false)
                    });
            }
            catch (error) {
                console.log(error)
            }
        }
        else {
            console.log(null, "return")
            return null
        }
    };
    const [LastPage, setLastPage] = useState()
    const filesGet = async () => {
        setLoading(true)
        try {
            await axiosConfig
                .get(`/mediamanager/media-items/?is_root=${is_root}&folder=${ParentFolder}&page_size=${RowPerPage}&page=${currentPage}&search=${search}&ordering=${direction}&is_trash=${Trash}`)
                .then((res) => {
                    const totalCount = res.data.count;
                    const pageSize = RowPerPage; // Assuming your page size is 20

                    const lastPage = Math.ceil(totalCount / pageSize);
                    setCount(res.data.count)
                    setLastPage(lastPage)
                    console.log(res.data.results)
                    setItems(res.data.results)
                    setLoading(false)
                    setNexTPage1(res.data.next)
                    setPrevious1(res.data.previous)
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false)
                });
        }
        catch (error) {
            console.log(error)
        }
    };
    const fiesGetNext = async () => {
        setLoading(true)
        if (NextPage1) {
            try {
                await axiosConfig
                    .get(NextPage1)
                    .then((res) => {
                        setItems(res.data.results)
                        setNexTPage1(res.data.next)
                        setPrevious1(res.data.previous)
                        setLoading(false)
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false)
                    });
            }
            catch (error) {
                console.log(error)
            }
        }
        else {
            console.log(null, "return")
            return null
        }
    };
    const fiesGetPrevious = async () => {
        setLoading(true)
        if (Previous1) {
            try {
                await axiosConfig
                    .get(Previous1)
                    .then((res) => {
                        setItems(res.data.results)
                        setNexTPage1(res.data.next)
                        setPrevious1(res.data.previous)
                        setLoading(false)
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false)
                    });
            }
            catch (error) {
                console.log(error)
            }
        }
        else {
            console.log(null, "return")
            return null
        }
    };
    const loadMoreFoldersData = () => {
        if (NextPage) {
            axiosConfig.get(NextPage).then((responce) => {
                setfolders((prevContent) => [...prevContent, ...responce.data.results]);
                setNexTPage(responce.data.next);
            });
        }
    };
    const loadMoreFilesData = () => {
        if (NextPage1) {
            setLoading1(true)
            axiosConfig.get(NextPage1).then((responce) => {
                setItems((prevContent) => [...prevContent, ...responce.data.results]);
                setNexTPage1(responce.data.next);
                setLoading1(false)
            });
        }
    };
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = (e) => {
        const { scrollTop, clientHeight, scrollHeight } = e.target;
        console.log("scrollTop:", scrollTop);
        console.log("clientHeight:", clientHeight);
        console.log("scrollHeight:", scrollHeight);

        // Use a small threshold to account for potential rounding issues
        const threshold = 1;

        if (clientHeight + scrollTop >= scrollHeight - threshold) {
            console.log("Reached bottom of scroll area. Loading more data.");
            loadMoreFilesData();
            loadMoreFoldersData();
        }
        setScrollPosition(scrollTop); // Update the scroll position
    };
    useEffect(() => {
        if (modalShow) {
            handvlemodalShow();
        }
        else {
            handlemodalclose()
        }
        folderGet()
        filesGet();
    }, [modalShow, ParentFolder, RowPerPage, search, currentPage, currentPagefolder,Trash, direction])
    function bytesToKB(sizeInBytes) {
        return (sizeInBytes / 1024).toFixed(2) + ' KB';
    }
    const [showToast, setShowToast] = useState(false);
    const [sortedData, setSortedData] = useState([]);
    const [sortOrder, setSortOrder] = useState('ascending');

    const handleSort = (order) => {
        const defaultName = ''; // Choose a suitable default value for the name property

        if (order === 'ascending') {
            const sortedAscending = [...Folders, ...Items].sort((a, b) =>
                (a.name || defaultName).localeCompare(b.name || defaultName)
            );
            console.log(sortedAscending);
            setData(sortedAscending);
            setSortOrder('ascending');
        } else if (order === 'descending') {
            const sortedDescending = [...Folders, ...Items].sort((a, b) =>
                (b.name || defaultName).localeCompare(a.name || defaultName)
            );
            console.log(sortedDescending);
            setData(sortedDescending);
            setSortOrder('descending');
        }
    };
    const handleDropdownToggle = () => {
        setIsOpen(!isOpen);
    };
    const handleCheckboxClick = (e) => {
        e.stopPropagation();
    };
    const dropdownRef = useRef();
    function removeTFromDateString(dateString) {
        if (typeof dateString === 'string') {
            return dateString.replace("T", " ").replace("Z", "");
        } else {
            // Handle invalid input if needed
            return dateString;
        }
    }

    const handleCreateFolder = () => {
        if (isCreatingFolder) {
            setIsCreatingFolder(false)
        }
        else {
            setIsCreatingFolder(true);
        }
    };

    const handleSaveFolder = async (folder_name) => {
        const newFormData = new FormData();
        newFormData.append("name", folder_name)
        newFormData.append("parent_folder", ParentFolder ? ParentFolder : "")
        try {
            await axiosConfig
                .post(`/mediamanager/media-folders/`, newFormData)
                .then((res) => {
                    filesGet()
                    folderGet()
                    handleCreateFolder();
                    setNewFolderName("")
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        catch (error) {
            console.log(error)
        }
    }
    const handleFolderNameChange = (event) => {
        setNewFolderName(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSaveFolder(event.target.value);
        }
    };
    const handleDelte = async (trash_data) => {
        if(trash_data.media_item_ids.length>0){
            try {
                await axiosConfig
                    .put(`/mediamanager/media-items/move_to_trash/`,trash_data,{
                        headers:{
                            'Content-Type': 'application/json',
                        }
                    })
                    .then((res) => {
                        setError(res.data.message)
                        setSelectedImagesForTrashing({
                            media_item_ids: [],
                        })
                        setShowToast(true)
                        filesGet()
                        folderGet()

                    })
                    .catch((error) => {
                        console.log(error.response.data.message);
                        setError(error.response.data.message)
                        if (error.response.data.message) {
                            setShowToast(true)
                            setimages({ images: [] })
                        }
                    });
            }
            catch (error) {
                console.log(error)
            }
        }
        }
    const hanldeDrop = (is_root, id) => {
        if (is_root) {
            setIsRoot(true)
            setParentFolder("")
            setActiveTab("")
            setTrash(false)
        }
        else {
            setParentFolder(id)
            setIsRoot(false)
            setActiveTab("")
        }
    }

    const handlesearch = async (e) => {
        setSearch(e.target.value)
    }
    const [mapindex, setmapindex] = useState()
    const HandlePopover = (ind) => {
        setmapindex(ind)
    }
    const handleLeave = () => {
        setHovered()
    }
    const handleFilterData = (id, name) => {
        setParentFolder(id)
        setIsRoot(false)
        const newBreadcrumb = {
            id: id,
            name: name,
        };

        const breadcrumbExists = bredCrumbs.some((breadcrumb) => breadcrumb.id === newBreadcrumb.id);

        if (!breadcrumbExists) {
            setBredcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, newBreadcrumb]);
        }


    }
    return (
        <>

            <Modal
                centered
                size="lg"
                show={lgShow}
                onHide={handlemodalclose}
                dialogClassName="custom-modal-width"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Choose images
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="fm-main">
                        <div className="left-menu">
                            <div>
                                <label htmlFor="imageInput1">
                                    <p className="upload-media btn">
                                        + Upload Media
                                    </p>
                                    <input
                                        type="file"
                                        id="imageInput1"
                                        name="images"
                                        accept="image/*"
                                        className="form-control"
                                        multiple
                                        onChange={handleFileChange}
                                        style={{ display: "none" }}
                                    />
                                </label>
                                <div>
                                    <div className="manage" onClick={() => setActiveTab('')}>MANAGE</div>

                                    <div
                                        className={`manage-options ${activeTab === 'Files' ? 'active-tab' : ''}`}
                                        onClick={() => hanldeDrop(true, "")}
                                    >
                                        Files
                                    </div>
                                    <div
                                        className={`manage-options ${activeTab === 'Favourites' ? 'active-tab' : ''}`}
                                        onClick={() => handleTabClick('Favourites')}
                                    >
                                        Favourites
                                    </div>
                                    <div
                                        className={`manage-options ${activeTab === 'Trash' ? 'active-tab' : ''}`}
                                        onClick={() => handleTabClick('Trash')}
                                    >
                                        Trash
                                    </div>
                                </div>
                            </div>
                            <div className="memory-used">
                                <div>190 MB used out of 2 GB <AiOutlineInfoCircle /></div>
                                <ProgressBar now={9} className="custom-progress-bar" />
                                <div style={{ color: '#1b7d78' }}>Upgrade</div>
                            </div>
                        </div>
                        <div className="top-menu">
                            <div className="top-menu-children">
                                <div className="top-menu-main">
                                    <div className="top-menu-options">
                                        <div className="fm-search">
                                            <AiOutlineSearch className="fm-icon" />
                                            <input type="text" placeholder="search" onChange={handlesearch} />
                                        </div>
                                        <AiOutlineFolderAdd className="icon-btn btn" onClick={handleCreateFolder} />
                                        <Dropdown show={isOpen} onToggle={handleDropdownToggle}>
                                            <Dropdown.Toggle variant="">
                                                <BsSortDownAlt className="icon-btn btn" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu ref={dropdownRef} style={{ zIndex: 10000 }} >
                                                <Dropdown.Item onClick={() => setDirection('name')}>Ascending</Dropdown.Item>
                                                <Dropdown.Item onClick={() => setDirection('-name')}>Descending</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleDelte(selectedImagesForTrashing)}>Trash Multiple</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="d-flex" style={{ cursor: "pointer" }}>
                                        {
                                            bredCrumbs.map((bredCrumbs, ind) => {
                                                console.log(bredCrumbs)
                                                return (
                                                    <div key={ind}>
                                                        <div onClick={() => hanldeDrop(bredCrumbs.name === "Files" ? true : false, bredCrumbs.id)}>{bredCrumbs.name}<HiOutlineChevronRight /></div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {loading ? (
                                        <div className="loader-container">
                                            <img src={require('../FormParser/images/loader.gif')} alt="Loading..." />
                                        </div>
                                    ) : (
                                        <>
                                            {(Folders.length === 0 && Items.length === 0 && !isCreatingFolder) ? (
                                                <Folder handleFileChange={handleFileChange} />
                                            ) : (
                                                <div className="files-folders" onScroll={(e) => handleScroll(e)}>
                                                    {isCreatingFolder && (
                                                        <div className="file-folder">
                                                            <img src={folderIcon} alt="Creating Folder" />
                                                            {isCreatingFolder && (
                                                                <div>
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Enter folder name"
                                                                        value={newFolderName}
                                                                        onChange={handleFolderNameChange}
                                                                        onKeyPress={handleKeyPress}
                                                                        className="form-control"
                                                                        autoFocus
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                    {[...Folders, ...Items].map((file, index) => (
                                                        <div
                                                            key={index}
                                                            className={`file-folder ${activestate.includes(index) ? 'active' : ''}`}
                                                            onDoubleClick={() => handleFilterData(file.id, file.name)}
                                                            onMouseEnter={() => setHovered(index)}
                                                            onMouseLeave={handleLeave}
                                                            onClick={(e) => handleImgClick(file, index, e)}
                                                        >
                                                            {file.image ? (
                                                                <img src={file.image} alt={file.name} style={{ width: '100%', height: '100%' }} />
                                                            ) : (
                                                                <img src={folderIcon} alt="Folder" style={{ width: '100%', height: '100%' }} />
                                                            )}
                                                            <div className="text-center style-taost-p">{file.name}</div>
                                                            {isHovered === index && (
                                                                <div className="vertical-dots" onClick={() => HandlePopover(index)}>
                                                                    <FiMoreVertical />
                                                                </div>
                                                            )}
                                                            {mapindex === index && (
                                                                <div className="vertical-dots-popover">
                                                                    <p onClick={() => handleDelte({
                                                                        media_item_ids:[file.id]
                                                                    })}>Trash</p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </>
                                    )}

                                    {loading1 && (
                                        <div className="spinner-container text-center">
                                            <Spinner animation="border" variant="success" />
                                        </div>
                                    )}
                                </div>
                                <div className="file-details">
                                    {activeImg && <div>
                                        <div className="fm-details-top">
                                            <div className="fm-details-img">
                                                <img src={(activeImg.image)} style={{ width: "100%", height: "100%" }} />
                                            </div>
                                            <div className="fm-img-title">{activeImg.Key}</div>
                                        </div>
                                        <div className="fm-details-content">
                                            <div className="file-info">File info</div>
                                            <div className="fm-details">
                                                <div>Type</div>
                                                <div>{activeImg.type && activeImg.type}</div>
                                            </div>
                                            <div className="fm-details">
                                                <div>Size</div>
                                                <div>{activeImg.Size && bytesToKB(activeImg.Size)}</div>
                                            </div>
                                            <div className="fm-details">
                                                <div>Resolution</div>
                                                <div>1800X3200px</div>
                                            </div>
                                            <div className="fm-details">
                                                <div>URL</div>
                                                <div>Copy URL</div>
                                            </div>
                                            <div className="fm-details">
                                                <div>Date Added</div>
                                                <div>{activeImg.LastModified && removeTFromDateString(activeImg.LastModified)}</div>
                                            </div>
                                            <div className="fm-details">
                                                <div>Added by</div>
                                                <div>anilkumarpandi....</div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            <div className="add-to-page">
                                <div className="btn" onClick={handleShot}>
                                    Add to Page
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="custom-toast">
                        <Toast show={showToast} onClose={() => setShowToast(false)}>
                            <Toast.Header>
                                {Error ? (
                                    <strong className="me-auto"></strong>
                                ) : (
                                    <strong className="me-auto">{loading ? `Uploading ${images.length} files` : `Upload ${images.length} Completed`}</strong>
                                )}
                            </Toast.Header>
                            <Toast.Body>
                                {Error && (
                                    <div className="Loginerror">
                                        <p>{Error}</p>
                                    </div>
                                )}
                                <div>
                                    {images.map((image, index) => (
                                        <div key={index}>
                                            <div className="d-flex justify-content-between">
                                                <div className="image-taost">
                                                    {image && (
                                                        <img
                                                            src={image ? URL.createObjectURL(image) : ""}
                                                            style={{ width: "100%" }}
                                                            alt={`Image ${index}`}
                                                        />
                                                    )}
                                                </div>
                                                <div className="style-taost-para">
                                                    <p>{image.name}</p>
                                                </div>
                                                <div>{bytesToKB(image.size)}</div>
                                                <div>{loading ? <div className="style-icon-activity"><FiLoader /></div> : <TiTick />}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Toast.Body>
                        </Toast>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export { FileManager }